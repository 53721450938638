import React, { useState } from "react";
// import { Link } from "react-router-dom";

import "./styles/footer.css";

const Footer = () => {
	const [currentYear, setYear] = useState(new Date().getFullYear());

	return (
		<React.Fragment>
			<div className="footer">
				{/* <div className="footer-links">
					<ul className="footer-nav-link-list">
						<li className="footer-nav-link-item">
							<Link to="/">Home</Link>
						</li>
						<li className="footer-nav-link-item">
							<Link to="/about">About</Link>
						</li>
						<li className="footer-nav-link-item">
							<Link to="/projects">Projects</Link>
						</li>
						<li className="footer-nav-link-item">
							<Link to="/articles">Articles</Link>
						</li>
						<li className="footer-nav-link-item">
							<Link to="/contact">Contact</Link>
						</li>
					</ul>
				</div> */}

				<div>
					All the applications do not include database hosting or provide database server features.
				</div>
				<div> • </div>

				<div className="footer-credits">
					<div className="footer-credits-text">
						© { currentYear } Filippo Maguolo. All Rights Reserved.
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Footer;
