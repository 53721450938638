const INFO = {
	main: {
		title: "DB Compass by Filippo Maguolo",
		name: "Filippo Maguolo",
		email: "info@dbcompass.net",
		logo: "../logo.png",
	},

	// socials: {
	// 	twitter: "https://twitter.com/",
	// 	github: "https://github.com/",
	// 	linkedin: "https://linkedin.com/",
	// 	instagram: "https://instagram.com/",
	// 	stackoverflow: "https://stackoverflow.com/",
	// 	facebook: "https://facebook.com/",
	// },

	homepage: {
		title: "DB Compass",
		description:
   		    `Access your database from anywhere.
			Directly access your server without the need of additional setup or intermediate layers.

			FEATURES
			• Connection over TLS encryption and SSH tunnel
			• Assisted table creation and alter structure
			• Explore, filter, sort, and quick search table data
			• Query script with OpenAI assisted composition and plan analysis
			• Database objects (tables, views, procedures, etc.)
			• Database statistics, sessions manager, server status graphs
			• ER Diagram Viewer
			• Advanced data grid for browse, edit, conditional formatting
			• Blob field preview, import and export
			• Import and Export in multiple formats
			• iCloud Sync for SQL and JS scripts
			• Shortcuts support
			`,
	},

	about: {
		title: "I’m Filippo Maguolo....",
		description:
			"",
	},

	articles: {
		title: "",
		description:
			"",
	},

	projects: [
		{
			title: "Multi DB Compass",
			description:
				"The convenience of access multiple databases from the same app. (Oracle, MySQL, PostgreSQL, SQL Server)",
			logo: "logo1.png",
			linkText: "AppStore",
			link: "https://apps.apple.com/us/app/multi-db-compass/id6736616179",
		},

		{
			title: "DB Compass for Oracle",
			description:
				"All the main Oracle features with you, including: Stored Procedure, Package, Recycle Bin, Server Output.",
			logo: "logo_ora.png",
			linkText: "AppStore",
			link: "https://apps.apple.com/us/app/db-compass-for-oracle/id6736586001",
		},

		{
			title: "DB Compass for MySQL",
			description:
				"MySQL access with X.509 certificate for maximum security and TLS v1.3.",
			logo: "logo_my.png",
			linkText: "AppStore",
			link: "https://apps.apple.com/it/app/mysql-mobile-client/id1406175528",
		},

		{
			title: "DB Compass for PostgreSQL",
			description:
				"PostgreSQL access with SASL authentication. Server log file browser.",
			logo: "logo_pg.png",
			linkText: "AppStore",
			link: "https://apps.apple.com/us/app/postgresql-client/id1233662353",
		},

		{
			title: "DB Compass for SQL Server",
			description:
				"",
			logo: "logo_mssql.png",
			linkText: "AppStore",
			link: "https://apps.apple.com/us/app/sql-server-mobile-client/id1406176302",
		},
	],
};

export default INFO;
